<template>
  <div id="transferFunds">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.transferBetweenAccs') }}</h2>
          <p></p>
        </div>
        <div class="form_box" v-if="flag">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <div class="top">
              <SelectForm
                v-model="selected.fromAccount"
                :label="$t('common.field.accNum')"
                name="fromAccount"
                class="showPlaceholder"
              >
                <el-option
                  v-for="item in formAccountOptions"
                  :key="item.value"
                  :value="item.login + ' - ' + item.currency"
                  :label="getCurrencySymbol(item)"
                  :data-testid="item.login"
                  :disabled="item.balance < 0"
                ></el-option
              ></SelectForm>

              <SelectForm
                v-model="ruleForm.toAccount"
                :label="$t('common.field.accNumTo')"
                name="toAccount"
                class="showPlaceholder"
              >
                <el-option
                  v-for="item in toAccountOptions"
                  :key="item.value"
                  :value="item.login"
                  :label="getCurrencySymbol(item)"
                  :data-testid="item.login"
                ></el-option
              ></SelectForm>
              <numeric-input
                v-model="ruleForm.amount"
                :label="$t('common.field.amt')"
                name="amount"
                :precision="2"
                :currency="selected.currentCurrency"
              ></numeric-input>
            </div>
            <p class="title">{{ $t('transferFunds.pleaseNote') }}</p>
            <p>{{ $t('transferFunds.warn1') }}</p>
            <p>{{ $t('transferFunds.warn3') }}</p>
            <div class="form_button">
              <el-button class="purple_button" @click="submitForm('ruleForm')" data-testid="submit">
                {{ $t('common.button.submit') }}
              </el-button>
            </div>
          </el-form>
        </div>
        <Success v-else>{{ $t('transferFunds.suc') }}</Success>
        <vDialog :show.sync="dialog.show" :text="dialog.text">
          <template v-slot:header>
            <img src="@/assets/images/dialog_info.png" class="info_icon" alt="info_icon" />
          </template>
          <template v-slot:body v-if="dialog.type === 'BLACKLISTED'">
            <i18n path="transfer.blackListWarn" tag="p">
              <template v-slot:mailTo>
                <a :href="'mailto:' + GLOBAL_DEPOSIT_EMAIL">{{ GLOBAL_DEPOSIT_EMAIL }}</a>
              </template>
            </i18n>
          </template>
          <template v-slot:btn>
            <div class="form_button">
              <el-button class="purple_button" @click="dialog.show = false" data-testid="confirm">
                {{ $t('common.button.confirm') }}
              </el-button>
            </div>
          </template>
        </vDialog>
        <vDialog :show.sync="positionDialog.show" :text="positionDialog.text">
          <template v-slot:btn>
            <el-button
              class="purple_button"
              v-if="positionDialog.showConfirm"
              @click="equityConfirm(applyTransfer)"
              data-testid="confirm"
            >
              {{ $t('common.button.confirm') }}
            </el-button>
            <el-button
              class="white_button"
              v-if="positionDialog.showCancel"
              @click="positionDialog.show = false"
              data-testid="cancel"
            >
              {{ $t('common.button.cancel') }}
            </el-button>
          </template>
        </vDialog>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import { apiGetTransferData_cp, apiApplyTransfer_cp, apiTransferPermission } from '@/resource';
import splitThousands from '@/util/splitThousands';
import vDialog from '@/components/vDialog';
import Success from '@/components/Result';
import checkPositionMixin from '@/mixins/page/tools/checkPosition';
import mixin from '@/mixins';

export default {
  name: 'transferFunds',
  components: { NumericInput, vDialog, Success },
  mixins: [checkPositionMixin, mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < 0) {
        callback(new Error(this.$t('common.formValidation.amt0')));
      } else {
        callback();
      }
    };
    return {
      flag: true,
      dialog: {
        show: false,
        text: '',
        type: ''
      },
      formAccountOptions: [],
      toAccountOptions: [],
      selected: {
        fromAccount: '',
        currentCurrency: ''
      },
      ruleForm: {
        amount: '',
        fromAccount: '',
        toAccount: ''
      },
      rules: {
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        fromAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change'
          }
        ],
        toAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    formAccountOptions(options) {
      // 預設第一筆
      if (options.length > 0) {
        const firstAccountIndex = options.findIndex(account => Number(account.balance) >= 0);
        if (firstAccountIndex !== -1) {
          this.selected.fromAccount =
            this.formAccountOptions[firstAccountIndex].login +
            ' - ' +
            this.formAccountOptions[firstAccountIndex].currency;
        }
      }
    },
    'selected.fromAccount'(value) {
      this.ruleForm.toAccount = '';
      [this.ruleForm.fromAccount, this.selected.currentCurrency] = [...value.split(' - ')];
      this.getToAccounts();
    }
  },
  methods: {
    getToAccounts() {
      this.toAccountOptions = this.formAccountOptions.filter(t => {
        return t.currency == this.selected.currentCurrency && t.login != this.ruleForm.fromAccount;
      });
    },
    getCurrencySymbol(item) {
      return (
        item.login +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.checkPrerequisiteAndSubmit();
        else return false;
      });
    },
    checkPrerequisiteAndSubmit() {
      apiTransferPermission({ account: this.ruleForm.fromAccount })
        .then(result => {
          if (result.data.data === 'BLACKLISTED') {
            this.dialog = {
              show: true,
              text: '',
              type: 'BLACKLISTED'
            };
          } else if (result.data.data === 'CREDITEXIST') {
            this.dialog = {
              show: true,
              text: this.$t('transfer.creditWarn'),
              type: 'CREDITEXIST'
            };
          } else if (result.data.data === 'PERMITTED') {
            this.checkPosition(this.ruleForm.fromAccount, this.ruleForm.amount, 'transferFunds', this.applyTransfer);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    applyTransfer() {
      apiApplyTransfer_cp({ ...this.ruleForm }, this.token)
        .then(result => {
          if (result.data.code == 0) this.flag = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchTradingAccount() {
      apiGetTransferData_cp()
        .then(resp => {
          if (resp.data.code === 0) this.formAccountOptions = resp.data.data.logins;
        })
        .catch(err => {
          return this.$message({ message: 'Fetch trading accounts failed.', type: 'error' });
        });
    }
  },
  mounted() {
    this.fetchTradingAccount();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transferFunds.scss';
</style>
